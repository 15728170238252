import { useState, useEffect } from 'react';

/* Main purpose for this helper is to provide a generic way to 'force' a 2 pass render.
 * The reason behind this is that some components can be rendered serverside but when initiallly rendered on the client produce different HTML.
 * When this happens React will throw a 'hyrdation' error/ warning. Ignoring this could lead to unexpected placing of DOM nodes.
 * See https://www.joshwcomeau.com/react/the-perils-of-rehydration/ for more information
 */
export default function useIsMounted() {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return isMounted;
}
