import { GeoLocation } from 'types/locationAPI';

export interface FavoriteLocation {
	name: string;
	id: number;
	origin: GeoLocation;
	favorite: boolean;
	dynamic?: boolean;
}

export interface LocationContextStateInterface {
	currentLocation: GeoLocation;
	favorites: FavoriteLocation[];
}

export enum LocationContextActionKind {
	UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION',
	ADD_FAVORITE_LOCATION = 'ADD_FAVORITE_LOCATION',
	REMOVE_FAVORITE_LOCATION = 'REMOVE_FAVORITE_LOCATION',
	UPDATE_FAVORITE_LOCATION = 'UPDATE_FAVORITE_LOCATION',
}

export interface LocationContextAction {
	type: LocationContextActionKind;
	location: GeoLocation | FavoriteLocation;
}
