import React, { useCallback, useEffect, useReducer } from 'react';
import Heading from 'components/Heading/Heading';
import { GeoLocation } from 'types/locationAPI';
import CompassIcon from '../../../public/icons/compass-dial.svg';
import { SearchResult, SearchResultGroup } from 'types/search';
import useKeyPress from 'utils/hooks/useKeyPress';
import { createFavoriteHref } from 'utils/createUrl';
import {
	Action,
	LocationResults,
	reducerActionsTypes,
	searchResultsDisplayVariant,
} from './SearchResults.types';

import styles from './SearchResults.module.scss';

type FavoriteActionType = {
	favorite: boolean;
	location: GeoLocation;
};

const searchResultsReducer = (state, action: Action) => {
	switch (action.type) {
		case reducerActionsTypes.Update:
			return {
				...state,
				totalResults: action.payload,
			};

		case reducerActionsTypes.ArrowUp:
			return {
				...state,
				selectedIndex:
					state.selectedIndex !== 0
						? state.selectedIndex - 1
						: state.totalResults.length - 1,
			};
		case reducerActionsTypes.ArrowDown:
			return {
				...state,
				selectedIndex:
					state.selectedIndex !== state.totalResults.length - 1
						? state.selectedIndex + 1
						: 0,
			};
		default:
			throw new Error();
	}
};

export default function SearchResults({
	searchResults,
	favorites,
	variant = 'default',
	favoriteAction,
	resultAction,
	resultType = 'link',
}: {
	searchResults: (SearchResultGroup | LocationResults)[];
	favorites?: GeoLocation[];
	variant: searchResultsDisplayVariant;
	favoriteAction: ({ favorite, location }: FavoriteActionType) => void;
	resultAction: (args0) => void;
	resultType: 'link' | 'button';
}) {
	const total: (SearchResult | GeoLocation)[] = searchResults
		.map((resultList) => resultList?.results?.map((result) => result))
		.flat(1);
	const arrowUpPressed = useKeyPress('ArrowUp');
	const arrowDownPressed = useKeyPress('ArrowDown');
	const enterPressed = useKeyPress('Enter');
	const initialState = { selectedIndex: 0, totalResults: total };
	const [state, dispatch] = useReducer(searchResultsReducer, initialState);
	const { selectedIndex, totalResults } = state;
	let resultIndex = 0;

	useEffect(() => {
		if (totalResults.length !== total.length) {
			dispatch({ type: reducerActionsTypes.Update, payload: total });
		}
	}, [total, totalResults.length]);

	useEffect(() => {
		if (arrowUpPressed) {
			dispatch({ type: reducerActionsTypes.ArrowUp });
		}
	}, [arrowUpPressed]);

	useEffect(() => {
		if (arrowDownPressed) {
			dispatch({ type: reducerActionsTypes.ArrowDown });
		}
	}, [arrowDownPressed]);

	const executeResultAction = useCallback(() => {
		const selectedResult = total[selectedIndex];
		resultAction(selectedResult);
	}, [selectedIndex, total, resultAction]);

	useEffect(() => {
		if (enterPressed) {
			executeResultAction();
			return;
		}
	}, [enterPressed, executeResultAction]);

	const headingStyle =
		variant === 'default'
			? styles.SearchResultsHeading
			: styles.SearchResultsHeadingLight;

	return (
		<section className={styles.SearchResultsContainer}>
			{searchResults.map((searchResult) => {
				return (
					<React.Fragment key={searchResult.id}>
						<header>
							<Heading variant="h4" classNameExt={headingStyle}>
								{searchResult?.title}
							</Heading>
						</header>
						<ul className={styles.SearchResults}>
							{searchResult?.results?.map((result) => {
								const isInFavoriteList = !!favorites?.find(
									(l) => l.id === result.id
								);

								const title = result.main
									? result.main
									: result.name;
								const isSelected =
									selectedIndex === resultIndex;
								const listStyle = isSelected
									? `${styles.SearchResult} ${styles.SearchResultSelected}`
									: `${styles.SearchResult}`;

								resultIndex++;
								return (
									<li
										key={title + resultIndex}
										className={listStyle}
									>
										{result.dynamic ? (
											<CompassIcon
												width="21"
												height="12"
											/>
										) : null}
										<ResultItem
											resultType={resultType}
											isSelected={isSelected}
											resultAction={resultAction}
											result={result}
											title={title}
										/>
										{result.name ? (
											<FavoriteIndicator
												favorite={isInFavoriteList}
												location={result}
												favoriteAction={favoriteAction}
												enterPressed={enterPressed}
											/>
										) : null}
									</li>
								);
							})}
						</ul>
					</React.Fragment>
				);
			})}
		</section>
	);
}

interface IResultItem {
	resultType: 'link' | 'button';
	isSelected: boolean;
	resultAction: (args0) => void;
	result: SearchResult | GeoLocation;
	title: string;
}

function ResultItem({
	resultType,
	isSelected,
	resultAction,
	result,
	title,
}: IResultItem) {
	if (resultType === 'link') {
		const href =
			'uri' in result
				? result.uri
				: createFavoriteHref({ favorite: result as GeoLocation });

		return (
			<a
				href={href}
				tabIndex={isSelected ? 0 : -1}
				onClick={() => {
					resultAction(result);
				}}
			>
				{title}&nbsp;
				{'name' in result ? <SubResult result={result} /> : null}
			</a>
		);
	}
	if (resultType === 'button') {
		return (
			<button
				className={styles.SearchResultButton}
				tabIndex={isSelected ? 0 : -1}
				onClick={() => {
					resultAction(result);
				}}
			>
				{title}&nbsp;
				{'name' in result ? <SubResult result={result} /> : null}
			</button>
		);
	}
	return null;
}

function SubResult({ result }: { result: GeoLocation }) {
	return (
		<span>
			{result.country}
			{result.foad ? `, ${result.foad?.name}` : ''}
		</span>
	);
}

function FavoriteIndicator({
	favorite,
	location,
	favoriteAction,
	enterPressed,
}: {
	favorite: boolean;
	location: GeoLocation;
	favoriteAction: ({ favorite, location }: FavoriteActionType) => void;
	enterPressed: boolean;
}) {
	const buttonStyle = favorite
		? styles.FavoriteButtonActive
		: styles.FavoriteButtonInActive;
	return (
		<button
			className={`${buttonStyle} ${styles.FavoriteButton}`}
			onMouseDown={(e) => e.preventDefault()}
			onClick={(e) => {
				e.preventDefault();
				if (!enterPressed) favoriteAction({ favorite, location });
			}}
			type="button"
		>
			<span className="visually-hidden">
				{favorite
					? 'Verwijder uit favorieten'
					: 'Voeg toe aan favorieten'}
			</span>
		</button>
	);
}
