import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import HomeIcon from 'public/icons/home.svg';
import { Menu } from 'types/siteAPI';
import { useLocationState } from 'utils/contexts/LocationsContext';
import { createFavoriteHref, getUrlForNavItem } from 'utils/createUrl';
import { useRouter } from 'next/router';
import SiteSearch from 'app/SiteSearch/SiteSearch';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import useIsMounted from 'utils/hooks/useIsMounted';
import useKeyPress from 'utils/hooks/useKeyPress';

import cssVariables from 'styles/variables.module.scss';
import styles from './Navigationbar.module.scss';

function isFocusInNavigationBar({ navBar }): boolean {
	const activeElement = document.activeElement;
	const activeElementInNavBar = navBar.contains(activeElement);

	return activeElementInNavBar;
}

const Navigationbar = ({ menu }: { menu: Menu[] }) => {
	const navigationBarRef = useRef(null);
	const [openMenu, setOpenMenu] = useState<string | null>(null);
	const { pathname } = useRouter();
	const isInWindow = useIsWindowSize({ mediaQuery: cssVariables.desktopS });
	const menuItemsToRender = menu.filter(
		(menuItem) => !menuItem.hideInMainMenu
	);
	const tabPressed = useKeyPress('Tab');

	useEffect(() => {
		const hasFocusInNavigation = isFocusInNavigationBar({
			navBar: navigationBarRef.current,
		});
		if (!hasFocusInNavigation) {
			setOpenMenu(null);
		}
	}, [setOpenMenu, tabPressed]);

	return (
		<div className={styles.Container}>
			<nav className={styles.MenuItemsContainer} ref={navigationBarRef}>
				{menuItemsToRender.map((menuItem) => {
					if (menuItem.name === 'Home') {
						const style = `${styles.MenuItem} ${styles.MenuItemHome}`;
						return (
							<Link
								href="/"
								key="home"
								className={style}
								title="Home"
							>
								<HomeIcon title="Home" />
							</Link>
						);
					}
					return (
						<button
							className={styles.MenuItem}
							key={menuItem.name}
							onFocus={() => setOpenMenu(menuItem.name)}
							onMouseOver={() => setOpenMenu(menuItem.name)}
							onKeyUp={(e) => {
								if (e.key === 'Escape') setOpenMenu(null);
							}}
							onMouseLeave={() => setOpenMenu(null)}
						>
							<span>{menuItem.name}</span>
							<SubMenu
								menuItem={menuItem}
								showSubMenu={openMenu === menuItem.name}
							/>
						</button>
					);
				})}
				{pathname !== '/' && isInWindow ? (
					<SiteSearch variant="navigation" />
				) : null}
			</nav>
		</div>
	);
};

function SubMenu({
	menuItem,
	showSubMenu,
}: {
	menuItem: Menu;
	showSubMenu: boolean;
}) {
	const subMenuItems = menuItem?.children || [];
	if (subMenuItems.length < 1) return null;

	return (
		<div
			className={styles.DropDownMenu}
			data-show={showSubMenu}
			key={menuItem.name}
		>
			{subMenuItems.map((subMenuItem) => {
				if (subMenuItem.name === '{locations}') {
					return <UserLocations key="favorites" />;
				}
				return (
					<a
						key={subMenuItem.name}
						href={getUrlForNavItem({
							root: menuItem,
							item: subMenuItem,
						})}
						className={styles.SubmenuItem}
					>
						{subMenuItem.name}
					</a>
				);
			})}
		</div>
	);
}

function UserLocations() {
	const { favorites } = useLocationState();
	const isMounted = useIsMounted();

	if (!isMounted) return null;

	return (
		<>
			{favorites.length > 0 &&
				favorites.map((favorite) => (
					<a
						key={favorite.id}
						href={createFavoriteHref({ favorite })}
						className={styles.SubmenuItem}
					>
						{favorite.name}
					</a>
				))}
		</>
	);
}

export default Navigationbar;
