import React from 'react';
import styles from './Button.module.scss';

interface IButton {
	primary?: boolean;
	onClick?: () => void;
	href?: string;
	fontSize?: string;
}

type ButtonComponentProps = IButton & React.HTMLProps<HTMLButtonElement>;

const Button = ({
	primary = false,
	onClick = undefined,
	fontSize,
	href,
	children,
}: ButtonComponentProps) => {
	const style = primary
		? `${styles.ButtonStyle} ${styles.Primary}`
		: styles.ButtonStyle;

	if (href) {
		return (
			<a
				href={href}
				className={style}
				style={{ '--fontSize': fontSize } as React.CSSProperties}
			>
				<span>{children}</span>
			</a>
		);
	}

	return (
		<button type="button" className={style} onClick={onClick}>
			<span>{children}</span>
		</button>
	);
};

export default Button;
