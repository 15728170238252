import getConfig from 'next/config';
import axios from 'redaxios';
import { SearchResultGroup } from 'types/search';

const { publicRuntimeConfig } = getConfig();
const { location, locale } = publicRuntimeConfig;

export async function getSearch({
  query,
  device = 'desktop',
}: {
  query: string;
  device?: string;
}): Promise<SearchResultGroup[]> {
  const response = await axios.get(
    `https://data.buienradar.nl/1.0/search?query=${query}&country=${location}&locale=${locale}&device=${device}`
  );
  return response.data;
}
