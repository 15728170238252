import kebabcase from 'lodash-es/kebabCase';
import { GeoLocation } from 'types/locationAPI';
import { Menu } from 'types/siteAPI';

type FavoriteHref = Pick<GeoLocation, 'asciiname' | 'countrycode' | 'id'>;

type INavItem = Pick<Menu, 'name' | 'url'>;

export function createFavoriteHref({
	favorite,
}: {
	favorite: FavoriteHref;
}): string {
	return `/weer/${favorite.asciiname}/${favorite.countrycode}/${favorite.id}`.toLowerCase();
}

export function getUrlForNavItem({
	root,
	parentItem,
	item,
}: {
	item: INavItem;
	root: INavItem;
	parentItem?: INavItem;
}): string {
	if (item.url?.includes('/')) return item.url;
	const rootUrl = kebabcase(root.name);
	const menuItemUrl = cleanMenuItemUrl(item.url);

	if (parentItem) {
		const parentUrl = cleanMenuItemUrl(parentItem.url);
		return `/${rootUrl}/${parentUrl}/${menuItemUrl}`;
	}

	return `/${rootUrl}/${menuItemUrl}`;
}

// Some urls defined in HQ end with a slash or even contain a full path.
// This helper either removes the extra slashes and/ or only takes the last part of the path
function cleanMenuItemUrl(url: string): string {
	if (!url) return '';
	if (url.includes('/')) {
		const urlSplit = url.split('/').filter((item) => item !== '');
		return urlSplit[urlSplit.length - 1];
	}
	return url;
}
