import styles from './Text.module.scss';

interface IText {
	children: React.ReactNode;
	fontSize?: string;
	textAlign?: string;
}

export default function Text({ children, fontSize, textAlign }: IText) {
	return (
		<p
			className={styles.Text}
			style={
				{
					'--font-size': fontSize,
					'--text-align': textAlign,
				} as React.CSSProperties
			}
		>
			{children}
		</p>
	);
}
