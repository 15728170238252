import getConfig from 'next/config';
import { datalayerPush } from 'utils/analytics';

export function setGTMMetaData() {
	document.addEventListener('gtmLoaded', () => {
		const { publicRuntimeConfig } = getConfig();
		const { dfpType, dfpNav } = getDfpValues();

		datalayerPush({
			abstractkey: '294457',
			seasonkey: '294458',
			type: dfpType,
			publicatiepunt: publicRuntimeConfig.publicationPoint,
			nav: dfpNav,
			settings: '',
			uuid: '',
			aj: '',
			event: 'view',
		});
	});
}

export function getDfpValues() {
	let dfpNav = 'home';
	let dfpType = 'home';
	let dfpTitel = '';
	const dfpUuid = document
		.querySelector('[data-page-id]')
		?.getAttribute('data-page-id');
	const currPath = window.location.pathname;
	if (currPath != '/') {
		// non-homepage
		dfpNav = currPath
			.replace(/\/$/, '')
			.substring(1, currPath.length - 1)
			.replace(/\//g, '.');
		dfpTitel = dfpNav.replace(/\./g, ' ');

		if (currPath.indexOf('/weer/') > -1) {
			// Remove digits for WPP pages
			dfpTitel = dfpTitel.replace(/[0-9]/g, '');
		}

		if (
			currPath.split('/').length == 3 &&
			currPath.indexOf('overbuienradar') == -1
		) {
			//overview pages are always on the 2nd level within the url structure with the exception of 'over buienradar' pages
			dfpType = 'index';
		} else if (
			currPath.indexOf('/weerzine') == -1 &&
			currPath.indexOf('/weer') > -1 &&
			currPath.split('/').length == 5
		) {
			// overview 'Weer per plaats'/ 'weather per location'
			dfpType = 'index';
		} else {
			dfpType = 'article';
		}
	}

	return {
		dfpNav,
		dfpTitel,
		dfpType,
		dfpUuid,
	};
}
