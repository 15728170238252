import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Menu } from 'types/siteAPI';
import ArrowIcon from 'public/icons/arrow.svg';
import { useLocationState } from 'utils/contexts/LocationsContext';
import { fixedNavItems } from './constants';
import { createFavoriteHref, getUrlForNavItem } from 'utils/createUrl';
import { useRouter } from 'next/router';
import SiteSearch from '../../../app/SiteSearch/SiteSearch';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import useIsMounted from 'utils/hooks/useIsMounted';

import styles from './NavigationBurger.module.scss';
import cssVariables from 'styles/variables.module.scss';

function toggleSubMenu({
	state,
	menuItem,
	cb,
}: {
	state: string;
	menuItem: string;
	cb: (any) => void;
}) {
	if (state === menuItem) return cb(''); // toggle current subMenu off
	return cb(menuItem);
}

const NavigationBurger = ({ menu }: { menu: Menu[] }) => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [subMenuOpen, setSubMenuOpen] = useState<string>(''); //TODO: menus should be 'toggled' also based on url
	const [lvl2MenuOpen, setlvl2MenuOpen] = useState<string>(''); //TODO: menus should be 'toggled' also based on url
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.desktopSMax,
	});
	const router = useRouter();

	useEffect(() => {
		function handleRouteChange() {
			if (menuOpen) setMenuOpen(false);
		}

		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [menuOpen, router.events]);

	useEffect(() => {
		if (menuOpen) {
			document.documentElement.classList.add('is-locked');
		} else {
			document.documentElement.classList.remove('is-locked');
		}

		return () => {
			document.body.style.removeProperty('overflow');
		};
	}, [menuOpen]);

	return (
		<>
			<Burger open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
			<nav
				className={styles.BurgerMenu}
				data-open={menuOpen}
				aria-hidden={!menuOpen}
				role="navigation"
			>
				{isInWindow ? <SiteSearch variant="default" /> : null}
				<ul className={styles.BurgerMenuList}>
					{menu.map((menuItem) => {
						if (menuItem.hideInMainMenu) return null;
						if (menuItem.name === 'Home')
							return (
								<HomeMenuItem
									hide={!!lvl2MenuOpen}
									menuItem={menuItem}
									key={menuItem.id}
								/>
							);

						return (
							<li
								className={styles.BurgerMenuListItem}
								key={menuItem.id}
								data-hide={
									!!lvl2MenuOpen &&
									subMenuOpen !== menuItem.name
								}
							>
								<button
									className={styles.BurgerMenuItem}
									data-hide={!!lvl2MenuOpen}
									id={lvl2MenuOpen}
									aria-expanded={
										subMenuOpen === menuItem.name
									}
									data-active={subMenuOpen === menuItem.name}
									onClick={() =>
										toggleSubMenu({
											state: subMenuOpen,
											menuItem: menuItem.name,
											cb: setSubMenuOpen,
										})
									}
								>
									{menuItem.name}
								</button>
								<button
									className={`${styles.BurgerMenuItem} rotateSvg`}
									title="Terug naar hoofdmenu"
									data-hide={!lvl2MenuOpen}
									aria-hidden={!lvl2MenuOpen}
									onClick={() =>
										toggleSubMenu({
											state: lvl2MenuOpen,
											menuItem: '',
											cb: setlvl2MenuOpen,
										})
									}
								>
									<ArrowIcon /> Terug naar hoofdmenu
								</button>
								<SubMenuLevel1
									open={subMenuOpen === menuItem.name}
									navItems={menuItem}
									lvl2MenuOpen={lvl2MenuOpen}
									setlvl2MenuOpen={setlvl2MenuOpen}
								/>
							</li>
						);
					})}
				</ul>
			</nav>
		</>
	);
};

function HomeMenuItem({ menuItem, hide }: { menuItem: Menu; hide: boolean }) {
	return (
		<li className={styles.BurgerMenuListItem} data-hide={hide}>
			<Link href="/" className={styles.BurgerMenuItem}>
				{menuItem.name}
			</Link>
		</li>
	);
}

function MyWeatherMenuItem({
	hide,
	children,
}: {
	hide: boolean;
	children: React.ReactNode;
}) {
	return (
		<li className={styles.BurgerMenuListItem} data-hide={hide}>
			<a
				href={'/mijn-weer'}
				className={`${styles.BurgerMenuItem} level-one`}
			>
				<ArrowIcon />
				{children}
			</a>
		</li>
	);
}

function SubMenuLevel1({
	open,
	navItems,
	lvl2MenuOpen,
	setlvl2MenuOpen,
}: {
	open: boolean;
	navItems: Menu;
	lvl2MenuOpen: string;
	setlvl2MenuOpen: React.Dispatch<React.SetStateAction<string>>;
}) {
	return (
		<div className={styles.BurgerSubMenu} data-open={open}>
			<ul aria-current={open && !lvl2MenuOpen}>
				{navItems.children &&
					navItems.children.map((subItem) => {
						if (subItem.hideInMainMenu) return null;
						if (subItem.name === '{locations}') {
							return (
								<UserLocations
									key={subItem.id}
									state={lvl2MenuOpen}
									cb={setlvl2MenuOpen}
								/>
							);
						}

						if (subItem.name === 'Overzicht') {
							return (
								<MyWeatherMenuItem
									key={subItem.id}
									hide={
										!!lvl2MenuOpen &&
										lvl2MenuOpen !== subItem.name
									}
								>
									{subItem.name}
								</MyWeatherMenuItem>
							);
						}

						return (
							<li
								className={styles.BurgerMenuListItem}
								key={subItem.id}
								data-hide={
									!!lvl2MenuOpen &&
									lvl2MenuOpen !== subItem.name
								}
							>
								<button
									className={`${styles.BurgerMenuItem} level-one`}
									aria-expanded={
										lvl2MenuOpen === subItem.name
									}
									data-active={subItem.name === lvl2MenuOpen}
									onClick={() =>
										toggleSubMenu({
											state: lvl2MenuOpen,
											menuItem: subItem.name,
											cb: setlvl2MenuOpen,
										})
									}
								>
									<ArrowIcon />
									{subItem.name}
								</button>
								<SubMenuLevel2
									open={lvl2MenuOpen === subItem.name}
									navItem={subItem}
									root={navItems}
								/>
							</li>
						);
					})}
			</ul>
		</div>
	);
}

function SubMenuLevel2({
	open,
	navItem,
	root,
}: {
	open: boolean;
	navItem: Menu;
	root: Menu;
}) {
	const router = useRouter();
	return (
		<div className={styles.BurgerSubMenu} data-open={open}>
			<ul aria-current={open} aria-hidden={!open}>
				{navItem.children &&
					navItem.children.map((subSubItem) => {
						if (subSubItem.hideInMainMenu) return null;
						return (
							<li
								className={styles.BurgerMenuListItem}
								key={subSubItem.id}
							>
								<a
									href={getUrlForNavItem({
										root,
										parentItem: navItem,
										item: subSubItem,
									})}
									className={`${styles.BurgerMenuItem} level-two`}
									onTouchStart={() => {
										router.prefetch(
											getUrlForNavItem({
												root,
												parentItem: navItem,
												item: subSubItem,
											})
										);
									}}
								>
									<ArrowIcon />
									{subSubItem.name}
								</a>
							</li>
						);
					})}
			</ul>
		</div>
	);
}

function Burger({ open, onClick }: { open: boolean; onClick: () => void }) {
	return (
		<button className={styles.Bun} onClick={onClick} title="Open menu">
			<div className={`${styles.Slice} top`} data-open={open} />
			<div className={`${styles.Slice} middle`} data-open={open} />
			<div className={`${styles.Slice} bottom`} data-open={open} />
		</button>
	);
}

function UserLocations({
	state,
	cb,
}: {
	state: string;
	cb: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { favorites } = useLocationState();
	const isMounted = useIsMounted();

	if (!isMounted) return null;

	return (
		<>
			{favorites.length > 0 &&
				favorites.map((favorite) => (
					<li
						className={styles.BurgerMenuListItem}
						key={favorite.id}
						data-hide={!!state && state !== favorite.name}
					>
						<button
							className={`${styles.BurgerMenuItem} level-one`}
							data-active={favorite.name === state}
							onClick={() =>
								toggleSubMenu({
									state,
									menuItem: favorite.name,
									cb,
								})
							}
						>
							<ArrowIcon />
							{favorite.name}
						</button>
						<div
							className={styles.BurgerSubMenu}
							data-open={state === favorite.name}
						>
							<ul>
								{fixedNavItems.map((navItem) => {
									return (
										<li
											className={
												styles.BurgerMenuListItem
											}
											key={navItem.name}
										>
											<a
												className={`${styles.BurgerMenuItem} level-two`}
												href={`${createFavoriteHref({
													favorite,
												})}${navItem.url}`}
											>
												<ArrowIcon />
												{navItem.name}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					</li>
				))}
		</>
	);
}

export default NavigationBurger;
