import React from 'react';
import kebabCase from 'lodash-es/kebabCase';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import useAppLocation from 'utils/hooks/useAppLocation';
import { LocationsProvider } from 'utils/contexts/LocationsContext';
import Layout from 'components/Layout/Layout';
import Advertisements from 'app/Advertisements';
import Location from 'app/Location/Location';
import { getNavigation } from 'utils/getNavigation';
import { getPage } from 'utils/getPage';
import { Menu, Page } from 'types/siteAPI';
import Error from 'next/error';
import { isClient } from 'utils/browser';
import MetaTags from 'app/MetaTags/MetaTags';
import { AppLocationEnum } from 'types/app';
import { AppProps } from 'next/app';
import { setGTMMetaData } from 'app/gtm';
import Script from 'next/script';
import getConfig from 'next/config';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { Cim } from 'app/Cim/Cim';
import { MetaPixel } from 'app/MetaPixel/MetaPixel';

import 'styles/Onetrust.scss';
import 'styles/GlobalStyles.scss';

const Otel = dynamic(() =>
	import('../src/app/Otel/Otel').then((mod) => mod.Otel)
);

interface IUrlParams {
	root: string;
	breadCrumbs: string[];
}

interface IpageProps {
	menu?: any;
	page?: Page;
	appLocation?: AppLocationEnum;
	statusCode?: 404 | 500;
}

type IChildMenu = undefined | Menu;

enum PageAction {
	NotFound = '404',
	Redirect = 'redirect',
}

interface IPageIdOrAction {
	id?: string;
	action?: PageAction;
}

interface IMyApp extends AppProps {
	menu: Menu[];
	pageProps: {
		statusCode: number;
		appLocation: AppLocationEnum;
		page?: Page;
		dehydratedState: unknown;
	};
}

if (isClient()) {
	setGTMMetaData();
}

function MyApp({ Component, pageProps, menu }: IMyApp) {
	const { publicRuntimeConfig } = getConfig();
	const appLocation = useAppLocation();
	const [queryClient] = React.useState(() => new QueryClient());
	const PageComponent = !pageProps.page ? (
		<Error statusCode={pageProps.statusCode ?? 404} />
	) : (
		<>
			<MetaTags
				page={pageProps.page}
				appLocation={pageProps.appLocation}
			/>
			<Component {...pageProps} />
		</>
	);

	if (process.env.NODE_ENV !== 'production') {
		if (process.env.NEXT_PUBLIC_API_MOCKING === 'true' && isClient()) {
			const { worker } = require('../mocks/client');
			worker.start();
		}
	}

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
			</Head>
			<OtelInit />
			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<LocationsProvider appLocation={appLocation}>
						<Location />
						<Advertisements />
						<Layout menu={menu}>{PageComponent}</Layout>
						<MetaPixel />
					</LocationsProvider>
				</Hydrate>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
			<div data-page-id={pageProps.page?.id} className="hidden"></div>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.setAttributeNode(d.createAttribute('data-ot-ignore'));j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;

        // Custom event to know when GTM is loaded and ready to use;
        j.addEventListener('load', function() {
          var _gtmLoadedEvent = new Event('gtmLoaded');
          document.dispatchEvent(_gtmLoadedEvent);
        });
        
        f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${publicRuntimeConfig.google.TagManagerId}');
      `}
			</Script>
			{appLocation === 'BE' ? <Cim /> : null}
		</>
	);
}

function OtelInit() {
	// NOTE: Rate-limit is disabled while going live (incrementally). Needs to be reinstated when traffic is too high
	// Only run for 10% of users
	// const initOtel = Math.random() < 0.1 ? true : false;
	// if (!initOtel) return null;
	return <Otel />;
}

MyApp.getInitialProps = async ({ ctx }) => {
	const { root, sub } = ctx.query;
	const urlParams: IUrlParams = {
		root,
		breadCrumbs: sub,
	};
	// Since we can't be sure that the requested url results in an actual we will try to find the pageId (to fetch it's contents) or execute an action (404 / redirect) depending on the requested page;
	let pageIdOrAction: IPageIdOrAction = {};
	let pageProps: IpageProps = {};

	try {
		//Get navigation structure of Buienradar
		const { menu } = await getNavigation({});

		// Root doesn't exist for 'homepage'
		if (!urlParams.root) {
			pageIdOrAction.id = 'home';
		}
		// e.g. /mijn-weer, /belgie, /nederland
		else if (urlParams.root && !urlParams.breadCrumbs) {
			pageIdOrAction = getPageIdOrAction({
				menu,
				urlParams,
				rootOnly: true,
			});
		} else if (urlParams.root === 'weer') {
			/* We have a specific page (buienradar.nl/weer/[city]/[countrycode]/[geolocationid]) that is used to show weather information regarding a specific city.
			 * This page is the same for each city and changes based on certain params. So for this page we only need the root.
			 */
			pageIdOrAction = getPageIdOrAction({
				menu,
				urlParams,
				rootOnly: true,
			});
		} else {
			pageIdOrAction = getPageIdOrAction({ menu, urlParams });
		}

		if (pageIdOrAction.action) {
			if (pageIdOrAction.action === PageAction.NotFound) {
				ctx.res.statusCode = 404;
				return { pageProps, menu };
			}
			// Some pages do not have a 'root' page and redirect back to the homepage;
			if (pageIdOrAction.action === PageAction.Redirect) {
				ctx.res.writeHead(302, { Location: '/' });
				ctx.res.end();
			}
		}

		// Based on pageId request the contents (metadata, layout etc) for a page;
		const { page } = await getPage({ pageId: pageIdOrAction.id });

		pageProps.page = page;

		pageProps.appLocation =
			process.env.APP_LOCATION === 'NL'
				? AppLocationEnum.NL
				: AppLocationEnum.BE;

		// Set cache headers for page so requests can be cached
		const cacheTime = page?.cacheDuration || 300;
		ctx.res.setHeader(
			'Cache-Control',
			`max-age=300, s-maxage=300, stale-while-revalidate=${cacheTime}`
		);

		return { pageProps, menu };
	} catch (e) {
		// TODO: Log to APM
		return {
			pageProps: {
				statusCode: 500,
			},
			menu: [],
		};
	}
};

export default MyApp;

function getPageIdOrAction({
	menu,
	urlParams,
	rootOnly,
}: {
	menu: Menu[];
	urlParams: IUrlParams;
	rootOnly?: boolean;
}): IPageIdOrAction {
	const rootMenu = menu.find((menuItem) => {
		const normalisedMenuName = kebabCase(menuItem.name);
		return normalisedMenuName === urlParams.root;
	});

	if (!rootMenu) {
		return { action: PageAction.NotFound };
	}

	if (rootOnly && rootMenu) {
		return rootMenu?.url === '/'
			? { action: PageAction.Redirect }
			: { id: rootMenu.id };
	}
	/*  After 'finding' the root we need to find which page we need to fetch based on the breadcrumbs.
	 *  We will loop throught the breadcrumbs to find the correct child per layer (root/child/child/child)
	 */
	const { breadCrumbs } = urlParams;
	const breadCrumbLength = breadCrumbs.length;
	let childMenu: IChildMenu = undefined;
	for (let i = 0; i < breadCrumbLength; i++) {
		const menu = !childMenu ? rootMenu : childMenu;
		childMenu = findChildPage({ menu, breadCrumb: breadCrumbs[i] });
	}

	return childMenu ? { id: childMenu.id } : { action: PageAction.NotFound };
}

function findChildPage({
	menu,
	breadCrumb,
}: {
	menu: Menu;
	breadCrumb: string;
}): IChildMenu {
	/*  The reason we do an 'include' match instead of an exact match is because some menu-items contain the entire relative path
	 *  instead of just the 'breadcrumb'.
	 */
	return menu.children?.find((menuItem) => {
		return menuItem.url?.includes(breadCrumb);
	});
}
