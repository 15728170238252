import Script from 'next/script';
import useConsent, { ConsentCategory } from 'utils/hooks/useConsent';

export function MetaPixel() {
	const hasConsent = useConsent(ConsentCategory.SOCIAL_MEDIA);

	if (!hasConsent) {
		return null;
	}

	return (
		<>
			<noscript>
				<img
					height="1"
					width="1"
					style={{ display: 'none' }}
					src="https://www.facebook.com/tr?id=206564248074782&ev=PageView&noscript=1"
					alt=""
				/>
			</noscript>
			<Script id="meta-pixel" strategy="afterInteractive">
				{`!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '206564248074782');
					fbq('track', 'PageView');
				`}
			</Script>
		</>
	);
}
