export const quickLinks = [
	{
		title: 'Weerstations',
		url: '/nederland/weerbericht/weerstations',
	},
	{
		title: '24 uurs radar',
		url: '/nederland/neerslag/buienradar/24uurs',
	},
	{
		title: 'Europa radar',
		url: '/wereldwijd/europa/buienradar',
	},
	{
		title: '7-daagse verwachting',
		url: '/wereldwijd/europa/7daagse',
	},
	{
		title: 'Satelliet Europa',
		url: '/wereldwijd/europa/satelliet',
	},
];

export const faqLinks = [
	{
		title: 'Veel gestelde vragen',
		url: '/overbuienradar/faq',
	},
	{
		title: 'Contact',
		url: '/overbuienradar/contact',
		action: (event) => {
			if (window.usabilla_live) {
				event.preventDefault();
				window.usabilla_live('click');
			}
		},
	},
	{
		title: 'Disclaimer',
		url: '/overbuienradar/disclaimer',
	},
	{
		title: 'Adverteren',
		url: 'https://www.adalliance.nl/netwerk/buienradar/',
	},
	{
		title: 'Buienradar Team',
		url: '/nederland/weerbericht/meteorologen',
	},
	{
		title: 'Cookie & Privacy',
		url: '/overbuienradar/privacy',
	},
	{
		title: 'Cookie instellingen',
		action: () => window.Optanon.ToggleInfoDisplay(),
	},
	{
		title: 'Gratis weerdata',
		url: '/overbuienradar/gratis-weerdata',
	},
];

export const socialMediaLinksNL = {
	facebook: {
		link: 'https://nl-nl.facebook.com/buienradar/',
		handle: 'Buienradar',
	},
	twitter: {
		link: 'https://twitter.com/buienradarnl',
		handle: '@BuienradarNL',
	},
	instagram: 'https://www.instagram.com/buienradar/?hl=nl',
	google: 'https://play.google.com/store/apps/details?id=com.supportware.Buienradar&hl=nl',
	apple: 'https://apps.apple.com/nl/app/buienradar/id621542526',
};
export const socialMediaLinksBE = {
	facebook: {
		link: 'https://www.facebook.com/buienradarbe/',
		handle: 'Buienradar',
	},
	twitter: {
		link: 'https://twitter.com/buienradarbe',
		handle: '@BuienradarBE',
	},
	instagram: 'https://www.instagram.com/buienradarbe/',
	google: 'https://play.google.com/store/apps/details?id=net.sharewire.buienradar.belgium',
	apple: 'https://apps.apple.com/be/app/buienradar-belgie/id903020219',
};
