import Script from 'next/script';
import useConsent, { ConsentCategory } from 'utils/hooks/useConsent';

export function Cim() {
	const hasConsent = useConsent(ConsentCategory.ANALYTICS);

	if (!hasConsent) return null;

	return (
		<>
			<Script
				strategy="lazyOnload"
				id="cim-script"
				dangerouslySetInnerHTML={{
					__html: `			
				var pp_gemius_identifier = '.Xo1NCOCXdMcDP_ZorrcC6PMXfyuTUbglbPt3Exsit3.67';
                var pp_gemius_extraparameters = new Array('lan=NL', 'key=meteo', 'subs=home');
                var pp_gemius_use_cmp = true;
                var pp_gemius_cmp_timeout = Infinity;
				var pp_gemius_cmp_purposes = { 2: [1, 8, 9, 10] };`,
				}}
			/>
			<Script
				strategy="lazyOnload"
				src="https://gabe.hit.gemius.pl/xgemius.js"
			/>
		</>
	);
}
