import { useEffect, useRef, useState } from 'react';
import { isClient } from 'utils/browser';

function useIsWindowSize({
	mediaQuery,
	initialState = false,
}: {
	mediaQuery: string;
	initialState?: boolean;
}): boolean {
	const isCancelled = useRef(false); // Is set to true when unmounted to prevent state updates while/ after unmounting of hook
	const [isInWindowSize, setIsInWindowSize] = useState<boolean>(initialState);

	useEffect(() => {
		if (!isClient()) return;
		const mql = window.matchMedia(mediaQuery);

		function handleChange(event) {
			if (event.matches) {
				setIsInWindowSize(true);
			} else {
				setIsInWindowSize(false);
			}
		}

		/* We need to check for 'addListener'/ 'removeListener' first to see if we are dealing with an older browser.
		 * If we don't check older browsers will break the app because they don't support the latest api (addEventListener/ removeEventListener)
		 */
		if (mql.addListener) {
			mql.addListener(handleChange);
		} else {
			mql.addEventListener('change', handleChange);
		}
		handleChange(mql);

		return () => {
			if (mql.removeListener) {
				mql.removeListener(handleChange);
			} else {
				mql.removeEventListener('resize', handleChange);
			}
			isCancelled.current = true;
		};
	}, [mediaQuery]);

	return isInWindowSize;
}

export default useIsWindowSize;
