import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Menu } from 'types/siteAPI';

export default function Layout({
	children,
	menu,
}: {
	children: React.ReactNode;
	menu: Menu[];
}) {
	return (
		<>
			<Header menu={menu} />
			{children}
			<Footer />
		</>
	);
}
