// TODO: Copy-/pasted current implementations to have no mismatch (needed for ads).
// Needs revising with PO & DPG

export function deviceOS() {
	const { userAgent } = window.navigator;
	if (userAgent.match(/(iPad|iPhone|iPod)/g)) {
		return 'ios';
	}
	if (userAgent.toLowerCase().indexOf('android') !== -1) {
		return 'android';
	}
	if (userAgent.toLowerCase().indexOf('wii') !== -1) {
		return 'wii';
	}
	if (userAgent.indexOf('Win') !== -1) {
		return 'windows';
	}
	if (userAgent.indexOf('Mac') !== -1) {
		return 'macos';
	}
	return 'other';
}

export function deviceType() {
	const ua = window.navigator.userAgent;
	return ua.match(
		/GoogleTV|SmartTV|Internet.TV|NetCast|NETTV|AppleTV|boxee|Kylo|Roku|DLNADOC|CE-HTML/i
	)
		? 'tv'
		: ua.match(/Xbox|PLAYSTATION.3|Wii/i)
		? 'tv'
		: ua.match(/iPad/i) ||
		  (ua.match(/tablet/i) && !ua.match(/RX-34/i)) ||
		  ua.match(/FOLIO/i)
		? 'tablet'
		: ua.match(/Linux/i) &&
		  ua.match(/Android/i) &&
		  !ua.match(/Fennec|mobi|HTC.Magic|HTCX06HT|Nexus.One|SC-02B|fone.945/i)
		? 'tablet'
		: ua.match(/Kindle/i) || (ua.match(/Mac.OS/i) && ua.match(/Silk/i))
		? 'tablet'
		: ua.match(
				/GT-P10|SC-01C|SHW-M180S|SGH-T849|SCH-I800|SHW-M180L|SPH-P100|SGH-I987|zt180|HTC(.Flyer|_Flyer)|Sprint.ATP51|ViewPad7|pandigital(sprnova|nova)|Ideos.S7|Dell.Streak.7|Advent.Vega|A101IT|A70BHT|MID7015|Next2|nook/i
		  ) ||
		  (ua.match(/MB511/i) && ua.match(/RUTEM/i))
		? 'tablet'
		: ua.match(
				/BOLT|Fennec|Iris|Maemo|Minimo|Mobi|mowser|NetFront|Novarra|Prism|RX-34|Skyfire|Tear|XV6875|XV6975|Google.Wireless.Transcoder/i
		  )
		? 'phone'
		: ua.match(/Opera/i) &&
		  ua.match(/Windows.NT.5/i) &&
		  ua.match(/HTC|Xda|Mini|Vario|SAMSUNG-GT-i8000|SAMSUNG-SGH-i9/i)
		? 'phone'
		: (ua.match(/Windows.(NT|XP|ME|9)/) && !ua.match(/Phone/i)) ||
		  ua.match(/Win(9|.9|NT)/i)
		? 'desktop'
		: ua.match(/Macintosh|PowerPC/i) && !ua.match(/Silk/i)
		? 'desktop'
		: ua.match(/Linux/i) && ua.match(/X11/i)
		? 'desktop'
		: ua.match(/Solaris|SunOS|BSD/i)
		? 'desktop'
		: ua.match(
				/Bot|Crawler|Spider|Yahoo|ia_archiver|Covario-IDS|findlinks|DataparkSearch|larbin|Mediapartners-Google|NG-Search|Snappy|Teoma|Jeeves|TinEye/i
		  ) && !ua.match(/Mobile/i)
		? 'desktop'
		: 'other';
}
