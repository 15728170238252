import styles from './AdSlot.module.scss';

export enum BannerType {
	Top = 'top',
	SkyScraper = 'skyscraper',
	Rectangle = 'rectangle',
	RectangleLarge = 'rectangleLarge',
}

type AdSlotProps = {
	type: BannerType;
	id?: string;
};

export default function AdSlot({ type, id }: AdSlotProps) {
	const style = styles[type];

	return (
		<div className={styles.AdContainer}>
			<div className={style} id={id} data-testid={id} />
		</div>
	);
}
