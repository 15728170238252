import React from 'react';
import Image from 'next/image';
import { useLocationState } from 'utils/contexts/LocationsContext';
import { createFavoriteHref } from 'utils/createUrl';
import {
	faqLinks,
	quickLinks,
	socialMediaLinksNL,
	socialMediaLinksBE,
} from './constants';
import TextLink from 'components/TextLink/TextLink';
import Heading from 'components/Heading/Heading';
import useIsMounted from 'utils/hooks/useIsMounted';
import useAppLocation from 'utils/hooks/useAppLocation';

import styles from './Footer.module.scss';

const ColumnHeading = ({ children }: { children: React.ReactNode }) => {
	return (
		<Heading variant="h4" color="#fff" margin="0 0 18px 0">
			{children}
		</Heading>
	);
};

const socialMediaLinksMap = {
	NL: socialMediaLinksNL,
	BE: socialMediaLinksBE,
};

const Footer = () => {
	const appLocation = useAppLocation();
	const socialMediaLinks = socialMediaLinksMap[appLocation];

	return (
		<>
			<footer className={styles.footer}>
				<div className={styles.footerContentWrapper}>
					<nav className={styles.Quicklinks}>
						<header>
							<ColumnHeading>Direct naar</ColumnHeading>
						</header>
						<ul className={styles.footerList}>
							{quickLinks.map((quicklink) => (
								<li
									className={styles.listItem}
									key={quicklink.title}
								>
									<TextLink
										href={quicklink.url}
										appearance="footer"
									>
										{quicklink.title}
									</TextLink>
								</li>
							))}
						</ul>
					</nav>
					<nav className={styles.About}>
						<header>
							<ColumnHeading>Over Buienradar</ColumnHeading>
						</header>
						<ul
							className={`${styles.footerListWrap} ${styles.footerList}`}
						>
							{faqLinks.map((aboutLink) => (
								<li
									className={styles.listItem}
									key={aboutLink.title}
								>
									<TextLink
										href={aboutLink.url}
										onClick={aboutLink.action}
										appearance="footer"
									>
										{aboutLink.title}
									</TextLink>
								</li>
							))}
						</ul>
					</nav>
					<nav className={styles.FavoriteLocations}>
						<header>
							<ColumnHeading>Mijn weer</ColumnHeading>
						</header>
						<UserLocations />
					</nav>
					<nav className={styles.Other}>
						<header>
							<ColumnHeading>Overig</ColumnHeading>
						</header>
						<a
							className={styles.SocialLink}
							href={socialMediaLinks.twitter.link}
							target="_blank"
							rel="noreferrer"
						>
							<div className={styles.ImageContainer}>
								<Image
									width={20}
									height={20}
									alt="twitter logo"
									src="https://cdn.buienradar.nl/resources/svg/social/twitter.svg"
								/>
							</div>
							{socialMediaLinks.twitter.handle}
						</a>
						<a
							className={styles.SocialLink}
							href={socialMediaLinks.facebook.link}
							target="_blank"
							rel="noreferrer"
						>
							<div className={styles.ImageContainer}>
								<Image
									width={20}
									height={20}
									alt="facebook logo"
									src="https://cdn.buienradar.nl/resources/svg/social/facebook.svg"
								/>
							</div>
							{socialMediaLinks.facebook.handle}
						</a>
						<a
							className={styles.SocialLink}
							href={socialMediaLinks.instagram}
							target="_blank"
							rel="noreferrer"
						>
							<div className={styles.ImageContainer}>
								<Image
									width={20}
									height={20}
									alt="instagram logo"
									src="https://cdn.buienradar.nl/resources/svg/social/instagram.svg"
								/>
							</div>
							Buienradar
						</a>
						<a
							className={styles.SocialLink}
							href={socialMediaLinks.google}
							target="_blank"
							rel="noreferrer"
							data-app-link=""
						>
							<div className={styles.ImageContainer}>
								<Image
									width={20}
									height={20}
									alt="Android"
									src="https://cdn.buienradar.nl/resources/svg/social/google-play.svg"
								/>
							</div>
							Download de Android app
						</a>
						<a
							className={styles.SocialLink}
							href={socialMediaLinks.apple}
							target="_blank"
							rel="noreferrer"
							data-app-link=""
						>
							<div className={styles.ImageContainer}>
								<Image
									width={20}
									height={20}
									alt="Apple"
									src="https://cdn.buienradar.nl/resources/svg/social/apple.svg"
								/>
							</div>
							Download de iOS app
						</a>
						{appLocation === 'NL' ? (
							<a
								className={styles.SocialLink}
								href="https://www.buienradar.nl/nederland/weerbericht/blog/buienradar-op-de-google-home-1f1f14"
								target="_blank"
								rel="noreferrer"
							>
								<Image
									width={160}
									height={50}
									alt="works with the Google Assistant"
									src="https://cdn.buienradar.nl/resources/images/thirdparty/google-assistant-badge.png"
								/>
							</a>
						) : null}
					</nav>
				</div>
			</footer>

			<footer className={styles.Copyright}>
				<p>
					© 2006 - {new Date().getFullYear()} RTL Nederland. Alle
					rechten voorbehouden. Geen tekst- en datamining.
				</p>
				{appLocation === 'BE' ? (
					<aside>
						<a
							href="http://www.cim.be/media/Internet/DisclaimerCIMInternet"
							target="_blank"
							rel="noreferrer"
						>
							<img
								loading="lazy"
								alt="CIM Internet Studies"
								src="https://cdn.buienradar.nl/resources/images/be/logo-cim-greyscale.png"
							/>
						</a>
					</aside>
				) : null}
			</footer>
		</>
	);
};

export default Footer;

function UserLocations() {
	const { favorites } = useLocationState();
	const isMounted = useIsMounted();

	if (!isMounted) return null;

	return (
		<>
			{favorites.map((favorite) => (
				<TextLink
					key={favorite.id}
					href={createFavoriteHref({ favorite })}
					appearance="footer"
				>
					{favorite.name}
				</TextLink>
			))}
		</>
	);
}
