import styles from './Heading.module.scss';

const fontSizes = {
	h1: '2.2rem',
	h2: '2.5rem',
	h3: '1.6rem',
	h4: '1.8rem',
};

export default function Heading({
	children,
	variant = 'h1',
	color,
	margin,
	classNameExt,
}: {
	children: React.ReactNode;
	variant: 'h1' | 'h2' | 'h3' | 'h4';
	color?: string;
	margin?: string;
	classNameExt?: string;
}) {
	const HeadingVariant = variant;

	return (
		<HeadingVariant
			className={
				classNameExt
					? `${styles.heading} ${classNameExt}`
					: styles.heading
			}
			style={
				{
					'--fontSize': fontSizes[variant],
					'--color': color,
					'--margin': margin,
				} as React.CSSProperties
			}
		>
			{children}
		</HeadingVariant>
	);
}
