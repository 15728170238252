import React, { useEffect } from 'react';

import styles from './Modal.module.scss';

const Modal = ({ children }: { children: React.ReactNode }) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.removeProperty('overflow');
		};
	}, []);

	return (
		<div className={styles.Overlay}>
			<div className={styles.Content}>{children}</div>
		</div>
	);
};

export default Modal;
