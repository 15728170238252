import axios from 'redaxios';
import { ICurrentSituation } from 'types/dataApi';

const getCurrentSituationSummary = async (
	weatherStationCode: number | undefined
): Promise<ICurrentSituation> => {
	if (weatherStationCode) {
		const res = await axios.get(
			`https://data.buienradar.nl/1.0/currentsituationsummary/${weatherStationCode}`
		);
		return res.data;
	}
	throw `weatherStationCode: ${weatherStationCode}  was missing/ not a number`;
};

export default getCurrentSituationSummary;
