import axios from 'redaxios';
import getConfig from 'next/config';
import { SiteAPItypes } from 'types/siteAPI';

const { publicRuntimeConfig } = getConfig();
const { siteName } = publicRuntimeConfig;

export async function getPage({
  pageId = 'home',
}: {
  pageId?: string;
}): Promise<SiteAPItypes> {
  const response = await axios.get(
    `https://data.buienradar.nl/1.0/site/pages/${siteName}/${pageId}`
  );
  return response.data;
}
