import getConfig from 'next/config';
import { ICurrentSituation } from 'types/dataApi';
import { deviceOS, deviceType } from './device';

type PageTargetingProps = {
	zender: string;
	nav: string;
	temp: string;
	neerslag: string;
	wind: string;
	uv?: string;
	muggen?: string;
	hooikoorts?: string;
	bbq?: string;
	luchtkwaliteit?: string;
};

const { publicRuntimeConfig } = getConfig();
const {
	google: { DfpSlotArticle, DfpSlotIndex, DfpSlotHome },
} = publicRuntimeConfig;

const settings = [
	{
		pagetype: 'home',
		adslots: [
			{
				size: '320',
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[320, 50],
					[320, 240],
					[300, 250],
					[320, 100],
					[320, 250],
				],
			},
			{
				size: ['500', '582'],
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[468, 60],
					[320, 100],
					[320, 50],
				],
			},
			{
				size: '768',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
				],
			},
			{
				size: '1024',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: ['1280', '1306'],
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: '1600',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 90],
					[970, 100],
					[970, 120],
					[970, 250],
				],
			},

			{
				size: '320',
				pos: 'rect2',
				divId: 'br-ad-sidebar-small',
				creative: 'simple',
				bannerSizes: [
					[320, 50],
					[320, 240],
					[300, 250],
					[320, 100],
					[320, 250],
					[320, 700],
					[300, 600],
				],
			},
			{
				size: ['500', '582'],
				pos: 'rect2',
				divId: 'br-ad-sidebar-small',
				creative: 'simple',
				bannerSizes: [
					[320, 240],
					[300, 250],
					[468, 60],
					[320, 50],
					[320, 100],
					[320, 250],
					[320, 700],
					[360, 700],
				],
			},
			{
				size: '768',
				pos: 'top2',
				divId: 'br-ad-sidebar-small',
				creative: 'rich',
				bannerSizes: [
					[728, 90],
					[468, 60],
				],
			},
			{
				size: '1024',
				pos: 'sky1',
				divId: 'br-ad-sidebar',
				creative: 'rich',
				bannerSizes: [[120, 600]],
			},
			{
				size: ['1280', '1306', '1600'],
				pos: 'sky1',
				divId: 'br-ad-sidebar',
				creative: 'rich',
				bannerSizes: [
					[300, 600],
					[300, 250],
					[160, 600],
					[120, 600],
				],
			},

			{
				size: '320',
				pos: 'rect1',
				divId: 'br-ad-home-myweather-small',
				creative: 'simple',
				bannerSizes: [
					[320, 50],
					[320, 240],
					[300, 250],
					[320, 100],
					[320, 250],
				],
			},
			{
				size: ['500', '582'],
				pos: 'rect1',
				divId: 'br-ad-home-myweather-small',
				creative: 'simple',
				bannerSizes: [
					[320, 240],
					[300, 250],
					[468, 60],
					[320, 50],
					[320, 100],
					[320, 250],
				],
			},
			{
				size: '768',
				pos: 'rect1',
				divId: 'br-ad-home-myweather-small',
				creative: 'rich',
				bannerSizes: [
					[336, 280],
					[300, 250],
				],
			},
			{
				size: '1024',
				pos: 'rect1',
				divId: 'br-ad-home-subcontent',
				creative: 'rich',
				bannerSizes: [[300, 250]],
			},
			{
				size: ['1280', '1306', '1600'],
				pos: 'rect1',
				divId: 'br-ad-home-subcontent',
				creative: 'rich',
				bannerSizes: [
					[336, 280],
					[300, 250],
				],
			},

			{
				size: [
					'320',
					'500',
					'582',
					'768',
					'1024',
					'1280',
					'1306',
					'1600',
				],
				pos: 'adfactor1',
				divId: 'br-ad-home-social',
				creative: 'rich',
				bannerSizes: [[336, 400]],
			},
		],
	},
	{
		pagetype: 'index',
		adslots: [
			{
				size: '320',
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[320, 50],
					[320, 100],
				],
			},
			{
				size: ['500', '582'],
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[468, 60],
					[320, 100],
					[320, 50],
				],
			},
			{
				size: '768',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
				],
			},
			{
				size: '1024',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: ['1280', '1306'],
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: '1600',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 90],
					[970, 100],
					[970, 120],
					[970, 250],
				],
			},
		],
	},
	{
		pagetype: 'article',
		adslots: [
			{
				size: '320',
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[320, 50],
					[320, 100],
				],
			},
			{
				size: ['500', '582'],
				pos: 'top1',
				divId: 'br-ad-header-small',
				creative: 'simple',
				bannerSizes: [
					[468, 60],
					[320, 100],
					[320, 50],
				],
			},
			{
				size: '768',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
				],
			},
			{
				size: '1024',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: ['1280', '1306'],
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 250],
				],
			},
			{
				size: '1600',
				pos: 'top1',
				divId: 'br-ad-header',
				creative: 'rich',
				bannerSizes: [
					[468, 60],
					[728, 90],
					[970, 90],
					[970, 100],
					[970, 120],
					[970, 250],
				],
			},
			{
				size: [
					'320',
					'500',
					'582',
					'768',
					'1024',
					'1280',
					'1306',
					'1600',
				],
				pos: 'advertorial1',
				divId: 'br-ad-advertorial-detail',
				creative: 'rich',
				bannerSizes: [[336, 400]],
			},
		],
	},
];

export default function initAds({
	hasConsent,
	currentSituationData,
}: {
	hasConsent: boolean;
	currentSituationData: ICurrentSituation;
}) {
	const { layout, hptoState } = getLayout();
	const improveSplitTest = getSplitTest();
	//const isWeerzinePage = window.location.pathname.toLowerCase().indexOf("weerzine") != -1;
	const isWeerzinePage = window.location.pathname
		.toLowerCase()
		.includes('weerzine');
	const dfpType = 'home';
	const page = getPageTargeting(isWeerzinePage, currentSituationData);
	const slot = getSlot(dfpType);
	const pageId = document
		.querySelector('[data-page-id]')
		?.getAttribute('data-page-id');
	const os = deviceOS();
	const device = deviceType() || undefined;
	const dfpvars = { os, device };
	let pag;

	switch (dfpType as string) {
		case 'index':
			pag = 'navigation';
			break;
		case 'article':
			pag = 'detail';
			break;
		default:
			pag = 'home';
	}

	const showAdAllianceBannersWeerzineHome = false; //window.buienradar?.weerzineShowsAdAllianceBanners && isWeerzinePage;

	if (showAdAllianceBannersWeerzineHome) {
		//TODO: first get normal ads working
		// only show AA Banners : this is currently not possible contractually (todo remove?)
		//self.addAdAllianceScript();
	} else {
		const googletag = window.googletag;
		googletag.cmd.push(function () {
			if (getScreenWidth() <= 768) {
				googletag.pubads().enableLazyLoad({
					fetchMarginPercent: 10, // Fetch slots within "fetchMarginPercent / 100" viewports.
					renderMarginPercent: 5, // Render slots within "renderMarginPercent / 100" viewports.
					mobileScaling: 1.0, // Multiplies the above values on mobile.
				});
			} else {
				googletag.pubads().enableSingleRequest();
			}

			// Non-Personalized settings
			googletag.pubads().disableInitialLoad();
			googletag.pubads().setRequestNonPersonalizedAds(1);
			googletag.pubads().setPrivacySettings({ limitedAds: true });
			googletag.pubads().setPrivacySettings({
				restrictDataProcessing: true,
			});
			googletag.enableServices();

			googletag
				.pubads()
				.setTargeting('pag', pag)
				.setTargeting('temp', [page.temp])
				.setTargeting('nav', [page.nav])
				.setTargeting('zender', [page.zender])
				.setTargeting('uuid', pageId)
				.setTargeting('neerslag', [page.neerslag])
				.setTargeting('wind', [page.wind])
				.setTargeting('uv', [page.uv])
				.setTargeting('muggen', [page.muggen])
				.setTargeting('bbq', [page.bbq])
				.setTargeting('hooikoorts', [page.hooikoorts])
				.setTargeting('luchtkwaliteit', [page.luchtkwaliteit])
				.setTargeting('buienlandcode', '')
				.setTargeting('selectedCountryCode', '')
				.setTargeting('buienlandcode', '')
				.setTargeting('selectedCountryCode', '')
				.setTargeting('splitsegment', improveSplitTest)
				.setTargeting('consapl', '1')
				.setTargeting('consstat', window?.OptanonActiveGroups || '1');
			// Define slots
			for (let i = 0; i < settings.length; i++) {
				const pagetype = settings[i].pagetype;
				if (pagetype.toLowerCase() === dfpType.toLowerCase()) {
					const adslots = settings[i].adslots;
					for (let ii = 0; ii < adslots.length; ii++) {
						const ad = adslots[ii];
						if (adMatchSize(layout, ad)) {
							const bannerSizes = ad.bannerSizes;
							const pos =
								typeof ad.pos === 'object' ? ad.pos : [ad.pos];
							googletag
								.defineSlot(slot, bannerSizes, ad.divId)
								.setTargeting('pos', pos)
								.setTargeting('hpto', hptoState)
								.setTargeting('creative', [ad.creative])
								.setTargeting('refresh', 'true')
								.addService(googletag.pubads());
						}
					}
				}
			}

			googletag
				.pubads()
				.setTargeting('consstat', window.OptanonActiveGroups);

			// Currently we don't support none personalized ads.
			if (!hasConsent) {
				return;
			}

			// Personalized settings
			googletag
				.pubads()
				.setTargeting('os', [dfpvars.os])
				.setTargeting('device', [dfpvars.device]);

			googletag.pubads().setRequestNonPersonalizedAds(0);
			googletag.pubads().setPrivacySettings({ limitedAds: false });
			googletag.pubads().setPrivacySettings({
				restrictDataProcessing: false,
			});

			googletag.cmd.push(function () {
				googletag.pubads().refresh();
			});
		});
	}
}

export function refreshAds() {
	const googletag = window.googletag;
	googletag?.pubads().refresh();
}

function adMatchSize(layout, ad) {
	let match = false;
	if (typeof ad.size === 'object') {
		for (let i = 0; i < ad.size.length; i++) {
			if (ad.size[i] === layout) {
				match = true;
				break;
			}
		}
	} else {
		match = ad.size === layout;
	}
	return match;
}

function getSplitTest(): string {
	const random = Math.random();
	if (random < 0.45) return 'a';
	if (random < 0.9) return 'b';
	return 'c';
}

function getScreenWidth() {
	const w = window;
	const d = document;
	const e = d.documentElement;
	const g = d.getElementsByTagName('body')[0];
	return w.innerWidth || e.clientWidth || g.clientWidth;
}

function getLayout(): {
	layout: string;
	hptoState: string;
} {
	const screenWidth = getScreenWidth();
	let layout;
	let hptoState; // HPTO -> HomePage TakeOver

	if (screenWidth < 500) {
		layout = '320';
		hptoState = 'screenWidthsmall';
	} else if (screenWidth < 582) {
		layout = '500';
		hptoState = 'small';
	} else if (screenWidth < 768) {
		layout = '582';
		hptoState = 'small';
	} else if (screenWidth < 1024) {
		layout = '768';
		hptoState = 'medium';
	} else if (screenWidth < 1280) {
		layout = '1024';
		hptoState = 'large';
	} else if (screenWidth < 1306) {
		layout = '1280';
		hptoState = 'screenWidthlarge';
	} else if (screenWidth < 1600) {
		layout = '1306';
		hptoState = 'screenWidthlarge';
	} else {
		layout = '1600';
		hptoState = 'screenWidthlarge';
	}

	return { layout, hptoState };
}

function getPageTargeting(
	isWeerzinePage: boolean,
	currentSituationData: ICurrentSituation
): PageTargetingProps {
	const page: PageTargetingProps = {
		zender: 'rtl4',
		nav: '',
		temp: '',
		neerslag: '',
		wind: '',
	};

	// nav
	if (isWeerzinePage) {
		page.nav = 'weerzine_overview_view';
	} else {
		let dfpNav = 'buienradar.home';
		const currPath = window.location.pathname;
		if (currPath !== '/') {
			// non-homepage
			dfpNav = currPath.substr(1, currPath.length).replace(/\//g, '.');
		}
		page.nav = dfpNav;
	}
	// weather
	const actualData = currentSituationData;

	if (actualData) {
		let temperature = actualData.temperature?.toString() ?? '0';
		if (temperature) {
			temperature = temperature.replace('-', 'min');
		}
		page.temp = temperature;
		page.neerslag = actualData.buienIndex
			? actualData.buienIndex.toString()
			: '';
		page.wind = actualData.windSpeedBft
			? actualData.windSpeedBft.toString()
			: '';
		page.uv = actualData.uvIndex ? actualData.uvIndex.toString() : '';
		page.muggen = actualData.mosquitoIndex
			? actualData.mosquitoIndex.toString()
			: '';
		page.hooikoorts = actualData.pollenIndex
			? actualData.pollenIndex.toString()
			: '';
		page.bbq = actualData.bbqIndex ? actualData.bbqIndex.toString() : '';
		page.luchtkwaliteit = actualData.airQualityIndex
			? actualData.airQualityIndex.toString()
			: '';
	} else {
		page.temp = page.neerslag = page.wind = '';
	}
	return page;
}

function getSlot(dfpType) {
	if (dfpType.toLowerCase() === 'home') {
		return DfpSlotHome;
	} else if (dfpType.toLowerCase() === 'index') {
		return DfpSlotIndex;
	}
	return DfpSlotArticle;
}
