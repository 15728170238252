import { GeoLocation } from 'types/locationAPI';

export const defaultLocationNL = {
	id: 2757783,
	name: 'De Bilt',
	asciiname: 'de bilt',
	alternatenames: ['de bilt', 'bilt', 'de bildt'],
	countrycode: 'NL',
	country: 'Nederland',
	featurecode: 'PPL',
	location: {
		lat: 52.11,
		lon: 5.18056,
	},
	boundaries: null,
	altitude: 0,
	elevation: 4,
	weatherstationid: 6260,
	weatherstationdistance: 1.11,
	continent: 'EU',
	foad: {
		name: 'Utrecht',
		code: 'UT',
	},
} as GeoLocation;

export const defaultLocationBE = {
	id: 2800866,
	name: 'Brussel',
	asciiname: 'brussels',
	alternatenames: [
		'an bhruiseil',
		'an bhruiséil',
		'bru',
		'breissel',
		'brisel',
		'brisele',
		'briuselis',
		'brjuksel',
		"brjusel'",
		"brjussel'",
		'brueksel',
		'bruessel',
		'bruesszel',
		'bruiseal',
		'bruksel',
		'bruksela',
		'brukseli',
		'brukselo',
		"brusehl'",
		'brusel',
		'brusela',
		'bruselas',
		'bruseles',
		'bruselj',
		'bruselo',
		'brusel·les',
		'brussel',
		'brussele',
		'brussels',
		'brussel·les',
		'bruxel',
		'bruxelas',
		'bruxellae',
		'bruxelles',
		'brwsel',
		'bryssel',
		'bryusel',
		'bryxelles',
		'bréissel',
		'brüksel',
		'brüssel',
		'brüsszel',
		'citta di bruxelles',
		'città di bruxelles',
		'kota brusel',
		'beulwisel',
		'braselsa',
		'brassels',
		'briuseli',
		'brwksl',
		'brysl',
		'bu lu sai er',
		'buryusseru',
		'βρυξέλλες',
		'брисел',
		'брусэль',
		'брюксел',
		'брюсель',
		'брюссель',
		'բրյուսել',
		'בריסל',
		'بروكسل',
		'بروکسل',
		'بريۇسسېل',
		'ܒܪܘܟܣܠ',
		'ब्रसेल्स',
		'บรัสเซลส์',
		'ბრიუსელი',
		'ブリュッセル',
		'布鲁塞尔',
		'브뤼셀',
	],
	countrycode: 'BE',
	country: 'België',
	featurecode: 'PPLC',
	location: {
		lat: 50.85045,
		lon: 4.34878,
	},
	boundaries: null,
	extra: [
		{
			type: 'onmap',
			key: '',
		},
	],
	altitude: 0,
	elevation: 28,
	weatherstationid: 10006447,
	weatherstationdistance: 5.7,
	continent: 'EU',
	'nl-nl': 'Brussel',
	'nl-be': 'Brussel',
} as GeoLocation;
