import Modal from '../Modal';
import Button from '../../Button';
import Text from 'components/Text/Text';
import { HorizontalBreak } from '../../../styles/theme';
import WindIcon from 'public/icons/compass-dial.svg';
import BRLogo from 'public/br-logo.svg';
import Heading from 'components/Heading/Heading';

import styles from './LocationPermissions.module.scss';

const LocationPermissionsPopup = ({
	onToggleOpen,
	onConsent,
}: {
	onToggleOpen: (arg0: boolean) => void;
	onConsent: ({
		consent,
		timestamp,
	}: {
		consent: boolean;
		timestamp: Date;
	}) => void;
}) => {
	return (
		<Modal>
			<div className={styles.Container}>
				<div className={styles.CenteredContent}>
					<BRLogo height="27" />
					<HorizontalBreak height={20} />
					<Heading variant="h4">
						Mogen we je locatie gebruiken voor het weer?
					</Heading>
					<HorizontalBreak height={20} />
					<div className={styles.IconContainer}>
						<WindIcon />
					</div>
					<HorizontalBreak height={10} />
					<Text>
						Indien je hier nog geen akkoord op hebt gegeven,
						verschijnt er zo een pop-up uit je browser waarin deze
						toestemming gevraagd wordt.
					</Text>
					<HorizontalBreak height={20} />
				</div>
				<Button
					primary
					onClick={() => {
						onConsent({ consent: true, timestamp: new Date() });
						onToggleOpen(false);
					}}
				>
					Is goed, toon de popup
				</Button>
				<HorizontalBreak height={12} />
				<Button
					onClick={() => {
						onConsent({ consent: false, timestamp: new Date() });
						onToggleOpen(false);
					}}
				>
					Nu niet, misschien later
				</Button>
				<HorizontalBreak height={40} />
				<Text fontSize="1.36rem" textAlign="center">
					Gebruik je Safari en wil je niet elke dag deze pop-up zien?
					Klik{' '}
					<a
						href="https://www.buienradar.nl/overbuienradar/faq#locatiesafari"
						target="_blank"
						rel="noreferrer"
					>
						hier
					</a>{' '}
					om dit aan te passen
				</Text>
			</div>
		</Modal>
	);
};

export default LocationPermissionsPopup;
