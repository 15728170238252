import { GeoLocation } from 'types/locationAPI';
import { SearchResult } from 'types/search';

export type searchResultsDisplayVariant = 'default' | 'light';

export enum reducerActionsTypes {
  Update = 'updateTotalResults',
  ArrowUp = 'arrowUp',
  ArrowDown = 'arrowDown',
}

export type Action =
  | {
      type: reducerActionsTypes.Update;
      payload: (SearchResult | GeoLocation)[];
    }
  | {
      type: reducerActionsTypes.ArrowUp;
    }
  | {
      type: reducerActionsTypes.ArrowDown;
    };

export interface LocationResults {
  id: string;
  title: string;
  results: GeoLocation[];
}
