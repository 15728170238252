import React from 'react';
import Link from 'next/link';
import Navigationbar from '../Navigationbar/Navigationbar';
import AdSlot, { BannerType } from 'components/AdSlot';
import { Menu } from 'types/siteAPI';
import NavigationBurger from '../NavigationBurger';
import BRLogo from 'public/br-logo.svg';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';

import cssVariables from 'styles/variables.module.scss';
import styles from './Header.module.scss';

export default function Header({ menu }: { menu: Menu[] }) {
	return (
		<>
			<header className={styles.header} role="banner">
				<Link href="/" className={styles.headerLink} id="home-link">
					<BRLogo title="Buienradar logo" width="182" height="25" />
				</Link>
				<NavigationBurger menu={menu} />
			</header>
			<HeaderAdSlot />
			<Navigationbar menu={menu} />
		</>
	);
}

function HeaderAdSlot() {
	const isInWindow = useIsWindowSize({ mediaQuery: cssVariables.tablet });

	if (isInWindow) return <AdSlot type={BannerType.Top} id="br-ad-header" />;

	return null;
}
