import React, { useEffect, useState } from 'react';
import Script from 'next/script';
import initAds from 'utils/loadAdvertisements';
import useConsent, { ConsentCategory } from 'utils/hooks/useConsent';
import { useQuery } from 'react-query';
import getCurrentSituationSummary from 'utils/getCurrentSituation';
import { useLocationState } from 'utils/contexts/LocationsContext';
import getConfig from 'next/config';

export default function Advertisements() {
	const { publicRuntimeConfig } = getConfig();
	const { assetPrefix } = publicRuntimeConfig;
	const [gptLoaded, setGptLoaded] = useState(false);
	const hasConsent = useConsent(ConsentCategory.ADVERTISING_AND_TRACKING);
	const { currentLocation } = useLocationState();
	const { data } = useQuery(
		['currentSituation', currentLocation?.weatherstationid],
		() => getCurrentSituationSummary(currentLocation.weatherstationid),
		{
			enabled:
				hasConsent &&
				typeof currentLocation?.weatherstationid === 'number',
		}
	);

	useEffect(function listenForGptToLoad() {
		document.addEventListener('gptLoaded', () => {
			setGptLoaded(true);
		});

		return () => {
			document.removeEventListener('gptLoaded', () => {
				return;
			});
		};
	}, []);

	useEffect(
		function loadAds() {
			if (hasConsent && gptLoaded && data) {
				initAds({ hasConsent, currentSituationData: data });
			}
		},
		[hasConsent, gptLoaded, data]
	);

	// We cannot load any gpt or prebid scripts atm before consent
	if (!hasConsent) return null;

	return (
		<>
			<Script
				src={`${assetPrefix}/scripts/pubmatic.js`}
				strategy="afterInteractive"
			/>
		</>
	);
}
