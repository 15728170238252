import { forwardRef } from 'react';
import MagnifyingGlass from '../../../public/icons/dark/search.svg';
import { SearchBarDisplayVariant } from './SearchBar.types';

import styles from './Searchbar.module.scss';

interface ISearchField extends React.InputHTMLAttributes<HTMLInputElement> {
	styleOverride?: string;
}

export interface ISearchBar extends ISearchField {
	variant?: SearchBarDisplayVariant;
	labelText?: string;
	children: React.ReactNode;
}

function preventSubmit(e) {
	e.preventDefault();
}

const SearchBar = forwardRef<HTMLDivElement, ISearchBar>(
	(props: ISearchBar, ref: React.Ref<HTMLDivElement>) => {
		const {
			variant = 'default',
			labelText,
			styleOverride = '',
			children,
			...inputfieldProps
		} = props;
		return (
			<div
				className={styles.searchBarContainer}
				data-variant={variant}
				ref={ref}
			>
				<form
					method="post"
					className={styles.searchBarForm}
					data-variant={variant}
					onSubmit={preventSubmit}
				>
					{labelText ? (
						<label
							htmlFor="search"
							className={styles.searchBarLabel}
							data-variant={variant}
						>
							{labelText}
						</label>
					) : null}
					<div
						className={styles.searchBarInputResults}
						data-variant={variant}
					>
						<input
							type="search"
							className={`${styles.searchBarInput} ${styleOverride}`}
							data-variant={variant}
							autoComplete="off"
							{...inputfieldProps}
						/>
						{children}
					</div>
					<div
						className={styles.searchBarIcon}
						data-variant={variant}
					>
						<MagnifyingGlass width="20" height="20" />
					</div>
				</form>
			</div>
		);
	}
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
