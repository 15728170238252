import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  getConsentedCategories,
  startConsentChangedListener,
  addConsentChangedListener,
  removeConsentChangedListener,
  waitForOneTrustScriptReady,
} from 'utils/onetrust';

export enum ConsentCategory {
  NATIVE = 'C0001',
  FUNCTIONALLY_REQUIRED = 'C0002',
  ANALYTICS = 'C0003',
  ADVERTISING_AND_TRACKING = 'C0004',
  SOCIAL_MEDIA = 'C0005',
  BG6_UNKNOWN = 'BG6',
}

export default function useConsent(consentCategory: ConsentCategory): boolean {
  const [categories, setCategories] = useState(getConsentedCategories());

  useEffect(() => {
    (async () => {
      await waitForOneTrustScriptReady();
      setCategories(getConsentedCategories());
    })();
  }, []);

  const consentCallback = useCallback(() => {
    setCategories(getConsentedCategories());
  }, []);

  useEffect(() => {
    (async () => {
      await startConsentChangedListener();
      addConsentChangedListener(consentCallback);
    })();
    return () => removeConsentChangedListener(consentCallback);
  }, [consentCallback]);

  return useMemo(
    () => categories.some((c) => c === consentCategory),
    [categories, consentCategory]
  );
}
