export const fixedNavItems = [
  {
    name: 'Overzicht',
    url: '',
  },
  {
    name: 'Buienradar',
    url: '/buienradar',
  },
  {
    name: '5-daagse per uur',
    url: '/5daagse',
  },
  {
    name: '14-daagse verwachting',
    url: '/14daagse',
  },
  {
    name: 'Klimaat',
    url: '/klimaat',
  },
  {
    name: 'Evenementen in de buurt',
    url: '/evenementen',
  },
];
