import Head from 'next/head';
import { AppLocationEnum } from 'types/app';
import { Page } from 'types/siteAPI';

export default function MetaTags({
  page,
  appLocation,
}: {
  page: Page;
  appLocation: AppLocationEnum;
}) {
  const { title, metaTitle, metaDescription, metaKeywords } = page;
  const appTitle = appLocation === 'NL' ? 'Buienradar.nl' : 'Buienradar.be';
  const description = metaDescription?.replace('&#235;', 'ë');

  return (
    <Head>
      <title>{`${appTitle} - ${metaTitle}`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:title" content={`Buienradar - ${title}`} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://cdn.buienradar.nl/resources/images/br-logo-square.png"
      />
    </Head>
  );
}
