import React from 'react';
import ArrowIcon from '/public/icons/arrow.svg';

import styles from './TextLink.module.scss';

type AppearanceType = 'text' | 'popup' | 'footer' | 'warningsbar';

interface ITextLink {
	appearance: AppearanceType;
	children: React.ReactNode;
	href?: string;
	onClick?: (args: unknown) => void;
}

const TextLink = ({
	href,
	children,
	appearance = 'text',
	onClick,
}: ITextLink) => {
	const content = (
		<>
			{appearance !== 'popup' && appearance !== 'warningsbar' &&
				<ArrowIcon />
			}
			{children}
		</>
	);

	if (onClick && !href) {
		return (
			<button
				className={`${styles.TextLink} ${styles.TextButton} ${
					appearance === 'text' ? '' : styles[appearance]
				}`}
				onClick={onClick}
				type="button"
			>
				{content}
			</button>
		);
	}
	return (
		<a
			href={href}
			onClick={onClick}
			className={`${styles.TextLink} ${
				appearance === 'text' ? '' : styles[appearance]
			}`}
		>
			{content}
		</a>
	);
};

export default TextLink;
