import { isClient } from './browser';

let otLoader;
export async function waitForOneTrustScriptReady() {
	if (otLoader) {
		return otLoader;
	}

	otLoader = new Promise<void>((resolve) => {
		// See public/scripts/optanon-wrapper.js
		document.addEventListener('optanonReady', () => {
			resolve();
		});
	});

	return otLoader;
}

const listeners: Array<() => void> = [];
let isListening = false;
export async function startConsentChangedListener(): Promise<void> {
	if (isListening) {
		return;
	}

	await waitForOneTrustScriptReady();

	window.Optanon.OnConsentChanged(() => {
		runCallbacks();
	});

	isListening = true;
}

function runCallbacks() {
	listeners.forEach((cb) => cb());
}

export function addConsentChangedListener(listener) {
	listeners.push(listener);
}

export function removeConsentChangedListener(listener) {
	const cbIndex = listeners.findIndex((cb) => cb === listener);
	if (cbIndex > 0) {
		listeners.splice(cbIndex, 1);
	}
}

export function getConsentedCategories(): [] {
	return getEnabledCategories(getOptanonCategoryString());
}

function getEnabledCategories(categoryList) {
	return categoryList.split(',').filter((c) => c);
}

function getOptanonCategoryString() {
	return (isClient() && window.OptanonActiveGroups) || '';
}

export function showConsentPopup(): void {
	window.Optanon.ToggleInfoDisplay();
}
