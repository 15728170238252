import { createContext, useReducer, useContext, useEffect } from 'react';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import {
	LocationContextActionKind,
	LocationContextAction,
	LocationContextStateInterface,
	FavoriteLocation,
} from './LocationContext.types';
import useIsMounted from 'utils/hooks/useIsMounted';
import { defaultLocationBE, defaultLocationNL } from './locationDefaults';
import { AppLocationEnum } from 'types/app';

// Actions
export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION';
export const UPDATE_FAVORITE_LOCATION = 'UPDATE_FAVORITE_LOCATION';
export const ADD_FAVORITE_LOCATION = 'ADD_FAVORITE_LOCATION';
export const REMOVE_FAVORITE_LOCATION = 'REMOVE_FAVORITE_LOCATION';

// Action creators
export function updateCurrentLocation(location) {
	return { type: UPDATE_CURRENT_LOCATION, location };
}
export function addFavoriteLocation(location) {
	return { type: ADD_FAVORITE_LOCATION, location };
}
export function removeFavoriteLocation(location) {
	return { type: REMOVE_FAVORITE_LOCATION, location };
}

function setInLocalstorage({ key, value }) {
	window.localStorage.setItem(key, JSON.stringify(value));
}
/* Locations that are stored in localstorage have a slighty different structure compared to locations that come from an API.
 * Storage-locations have all props of a 'normal' location nested within in the prop 'origin'. To prevent 'leaking' this throughout the entire app,
 * this helper function extracts the origin prop and we pass that to every consumer instead of the original localstorage item.
 */
function getOriginFromFavorites(favorites) {
	return favorites.map((favorite) => {
		return favorite.origin;
	});
}

const locationsReducer = (
	state: LocationContextStateInterface,
	action: LocationContextAction
) => {
	switch (action.type) {
		case UPDATE_CURRENT_LOCATION: {
			return {
				...state,
				currentLocation: action.location,
			};
		}
		case ADD_FAVORITE_LOCATION: {
			const newFavorites = [...state.favorites];
			const maxFavoriteLocations = 6;

			if (newFavorites.length === maxFavoriteLocations) {
				window.alert(
					`U kunt maximaal ${maxFavoriteLocations} locaties opslaan.`
				);
				return {
					...state,
				};
			}

			const newFavorite = {
				name: action.location.name,
				id: action.location.id,
				favorite: true,
				origin: {
					...action.location,
				},
			};

			newFavorites.unshift(newFavorite);
			setInLocalstorage({
				key: 'buienradar.user.locations',
				value: newFavorites,
			});

			return {
				...state,
				favorites: newFavorites,
			};
		}
		case REMOVE_FAVORITE_LOCATION: {
			const currentFavorites = [...state.favorites];
			const indexOfFavoritedLocation = currentFavorites.findIndex(
				(cf) => cf.origin.id === action.location.id
			);
			if (indexOfFavoritedLocation !== -1) {
				currentFavorites.splice(indexOfFavoritedLocation, 1);
			}
			setInLocalstorage({
				key: 'buienradar.user.locations',
				value: currentFavorites,
			});
			return {
				...state,
				favorites: currentFavorites,
			};
		}
		default:
			return state;
	}
};

type ILocationActions = {
	type: string;
	location: object;
};

const LocationsStateContext =
	createContext<LocationContextStateInterface | null>(null);
const LocationsUpdaterContext =
	createContext<React.Dispatch<ILocationActions> | null>(null);

export const defaultLocations = {
	NL: defaultLocationNL,
	BE: defaultLocationBE,
};

function LocationsProvider({
	appLocation,
	children,
}: {
	appLocation: AppLocationEnum;
	children: React.ReactNode;
}) {
	const defaultLocation = defaultLocations[appLocation];
	const [favoritedLocations] = useLocalStorage<FavoriteLocation[]>(
		'buienradar.user.locations',
		[]
	);
	const isMounted = useIsMounted();
	const currentLocation = defaultLocation;
	const [locationsData, dispatch] = useReducer(locationsReducer, {
		currentLocation,
		favorites: favoritedLocations,
	});

	/* Wrapped setting the 'currentLocation' with 'isMounted' hook to prevent hydration errors.
	 * When a user has a favorite location or turned on dynamic location chances are these will differ from the default (De Bilt/ Brussel).
	 * The default location will be used serverside to render the page, but hydration happens on the client it will load new data from storage causing the initial hydration cycle to
	 * differ from the initial ssr render. To 'fix' this we delay setting the current location with a favorite/ dynamic location until the second render.
	 */
	useEffect(() => {
		if (isMounted) {
			if (favoritedLocations.length > 0) {
				const dynamicLocationIndex = favoritedLocations.findIndex(
					(loc) => loc.origin.dynamic
				);
				const newLocation =
					dynamicLocationIndex !== -1
						? favoritedLocations[dynamicLocationIndex]
						: favoritedLocations[0];

				dispatch({
					type: LocationContextActionKind[UPDATE_CURRENT_LOCATION],
					location: newLocation.origin,
				});
			}
		}
	}, [isMounted, favoritedLocations]);

	return (
		<LocationsStateContext.Provider value={locationsData}>
			<LocationsUpdaterContext.Provider value={dispatch}>
				{children}
			</LocationsUpdaterContext.Provider>
		</LocationsStateContext.Provider>
	);
}

function useLocationState() {
	const locationState = useContext(LocationsStateContext);
	if (!locationState) {
		throw new Error(
			'useLocationState must be used within a LocationProvider'
		);
	}
	const favoritesWithoutOrigin = getOriginFromFavorites(
		locationState.favorites
	);
	const locationStateWithoutOrigin = {
		currentLocation: locationState.currentLocation,
		favorites: favoritesWithoutOrigin,
	};
	return locationStateWithoutOrigin;
}

function useLocationUpdater() {
	const dispatch = useContext(LocationsUpdaterContext);
	if (!dispatch) {
		throw new Error(
			'useLocationUpdater must be used within a LocationProvider'
		);
	}
	return dispatch;
}

export { LocationsProvider, useLocationUpdater, useLocationState };
