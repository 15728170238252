// TODO: need to be removed
export const HorizontalBreak = function ({ height = 8 }: { height?: number }) {
  return <div style={{ height: `${height}px` }}></div>;
};

export const colors = {
  white: '#fff',
  lightBlue: '#e2eff8',
  reallyLightBlue: '#f5fafD',
  waterBlue: '#152b81',
  skyBlue: '#5a9cd7',
  lightGreen: '#01ad01',
  landGreen: '#009100',
  grey: '#c3c3c3',
  lightGrey: '#f9f9f9',
  darkGrey: '#898989',
  grey2: '#e1e1e1',
  grey3: '#5f666c',
  kingsOrange: '#e85300',
};
