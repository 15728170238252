import { useState, useCallback, useEffect, useRef } from 'react';
import SearchBar from 'components/Search/SearchBar';
import SearchResults from 'components/Search/SearchResults';
import { useQuery } from 'react-query';
import { GeoLocation } from 'types/locationAPI';
import { SearchResultGroup } from 'types/search';
import {
	addFavoriteLocation,
	removeFavoriteLocation,
	useLocationState,
	useLocationUpdater,
} from 'utils/contexts/LocationsContext';
import { getLocationsByQuery } from 'utils/getLocation';
import { getSearch } from 'utils/getSearch';
import useDebounce from 'utils/hooks/useDebounce';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { SearchBarDisplayVariant } from 'components/Search/SearchBar.types';
import { useRouter } from 'next/router';
import { createFavoriteHref } from 'utils/createUrl';

import cssVariables from 'styles/variables.module.scss';

type LocationResultsProps = {
	id: string;
	results: GeoLocation[];
	title: string;
};

function saveRecent({ currentState, newItem, callback }) {
	const savedSearches = [...currentState.searched] ?? [];
	const alreadySaved = savedSearches.some((item) => {
		if (item.type === 'location') {
			return item.origin.id === newItem.id;
		} else {
			return item.main === newItem.main;
		}
	});
	if (alreadySaved) return;
	if (savedSearches.length === 3) savedSearches.pop();
	if (newItem.name) {
		savedSearches.splice(0, 0, {
			origin: newItem,
			type: 'location',
		});
	} else {
		savedSearches.splice(0, 0, newItem);
	}
	callback({
		searched: savedSearches,
	});
}

function useDevice() {
	const isMobile = useIsWindowSize({ mediaQuery: cssVariables.mobileLMax });
	const isTablet = useIsWindowSize({
		mediaQuery: `${cssVariables.mobileL} and ${cssVariables.tabletMax}`,
	});

	if (isMobile) return 'mobile';
	if (isTablet) return 'tablet';
	return 'desktop';
}

export default function SiteSearch({
	variant = 'default',
	labelText,
}: {
	variant: SearchBarDisplayVariant;
	labelText?: string;
}) {
	const router = useRouter();
	const locationDispatch = useLocationUpdater();
	const { favorites } = useLocationState();
	const device = useDevice();
	const [showResults, setShowResults] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
	const [recentSearches, setRecentSearches] = useLocalStorage(
		'buienradar.search.history',
		{ searched: [] }
	);
	const SiteSearchRef = useRef<HTMLDivElement | null>(null);
	const searchResults: (SearchResultGroup | LocationResultsProps)[] = [];

	const { data: searchData, isLoading: isSearchDataLoading } = useQuery<
		SearchResultGroup[],
		Error
	>(
		['search', debouncedSearchTerm],
		() => getSearch({ query: debouncedSearchTerm, device }),
		{
			enabled: Boolean(debouncedSearchTerm),
			refetchOnWindowFocus: false,
		}
	);

	const { data: locationData, isLoading: isLocationDataLoading } = useQuery<
		GeoLocation[],
		Error
	>(
		['locations', debouncedSearchTerm],
		() => getLocationsByQuery({ query: debouncedSearchTerm }),
		{
			enabled: Boolean(debouncedSearchTerm),
			refetchOnWindowFocus: false,
		}
	);

	const toggleResults = () => {
		if (!showResults) {
			setShowResults(true);
		}
	};

	const toggleFavorite = useCallback(
		({ favorite, location }) => {
			if (favorite) {
				locationDispatch(removeFavoriteLocation(location));
			} else {
				locationDispatch(addFavoriteLocation(location));
			}
		},
		[locationDispatch]
	);

	const resultAction = useCallback(
		(result) => {
			const href =
				'uri' in result
					? result.uri
					: createFavoriteHref({ favorite: result });

			saveRecent({
				currentState: recentSearches,
				newItem: result,
				callback: setRecentSearches,
			});
			router.push(href);
		},
		[recentSearches, setRecentSearches, router]
	);

	const handleClickOutside = useCallback((event: MouseEvent): void => {
		if (
			SiteSearchRef.current &&
			!SiteSearchRef.current.contains(event.target as Node)
		) {
			setShowResults(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	if (locationData && !isLocationDataLoading) {
		const extendedLocationResults = {
			id: 'locations',
			results: locationData,
			title: 'Plaatsen',
		};
		searchResults.push(extendedLocationResults);
	}
	if (searchData && !isSearchDataLoading) {
		const pageResults = searchData?.find((data) => data.id === 'pages');
		if (pageResults) searchResults.push(pageResults);
	}
	if (recentSearches.searched.length > 0) {
		const recentSearchesResults = {
			id: 'recent-searches',
			results: formatRecentSearches(recentSearches.searched),
			title: 'Eerder gezocht',
		};
		searchResults.push(recentSearchesResults);
	}
	searchResults.filter((item) => item);

	return (
		<SearchBar
			name="site-search"
			ref={SiteSearchRef}
			labelText={labelText}
			placeholder="'onweer', 'Antwerpen', 'Parijs'"
			variant={variant}
			onChange={(event) => setSearchTerm(event.target.value)}
			onFocus={() => toggleResults()}
		>
			{showResults && searchResults.length > 0 ? (
				<SearchResults
					variant={variant === 'default' ? 'light' : 'default'}
					searchResults={searchResults}
					favorites={favorites}
					favoriteAction={toggleFavorite}
					resultAction={resultAction}
					resultType="link"
				/>
			) : null}
		</SearchBar>
	);
}

function formatRecentSearches(results) {
	return results.map((result) => {
		if (result.type === 'location') {
			return result.origin;
		}
		return result;
	});
}
