import axios from 'redaxios';
import { GeoLocation, LocationQuery } from 'types/locationAPI';

type LocationByCoords = {
	lat?: string | number;
	lon?: string | number;
	locale?: string;
	force?: boolean;
};

export async function getLocationByCoords({
	lat,
	lon,
	locale = 'nl-NL',
	force = false,
}: LocationByCoords): Promise<GeoLocation> {
	if (typeof lat === undefined || typeof lon === undefined) {
		return Promise.reject(new Error('Invalid lat/ lon'));
	}
	const response = await axios.get(
		`https://location.buienradar.nl/1.1/location/geo?lat=${lat}&lon=${lon}&locale=${locale}&force=${force}`
	);
	return response.data;
}

export async function getLocationsByQuery({
	query,
	country = 'NL',
	locale = 'nl-NL',
}: LocationQuery): Promise<[GeoLocation]> {
	const response = await axios.get(
		`https://location.buienradar.nl/1.1/location/search?query=${query}&country=${country}&locale=${locale}`
	);
	return response.data;
}

export async function getLocationById({
	id,
}: {
	id: number;
}): Promise<GeoLocation> {
	const response = await axios.get(
		`https://location.buienradar.nl/1.1/location/id/${id}`
	);
	return response.data;
}
