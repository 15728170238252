import { useEffect, useState } from 'react';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import LocationPermissionsPopup from 'components/Modal/LocationPermissions/LocationPermissions';
import { useQuery } from 'react-query';
import { getLocationByCoords } from 'utils/getLocation';
import {
	updateCurrentLocation,
	addFavoriteLocation,
	useLocationUpdater,
	useLocationState,
} from 'utils/contexts/LocationsContext';

interface PositionConsentTypes {
	consent: boolean;
	timestamp: Date;
}

function reportRejection(event) {
	//TODO: report this event to an APM
	console.log('reportRejection', event);
}

export default function Location() {
	const { favorites } = useLocationState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [positionConsent, setPositionConsent] =
		useLocalStorage<PositionConsentTypes | null>(
			'buienradar.positionconsent',
			null
		);
	const [userPosition, setUserPosition] =
		useState<GeolocationPosition | null>(null);
	const hasDynamicLocation = favorites.findIndex((loc) => loc.dynamic) !== -1;
	const { data, status } = useQuery(
		'locationByCoords',
		() =>
			getLocationByCoords({
				lat: userPosition?.coords?.latitude,
				lon: userPosition?.coords?.longitude,
			}),
		{
			enabled: userPosition !== null,
		}
	);
	const dispatch = useLocationUpdater();

	useEffect(() => {
		if (status === 'success') {
			const location = {
				...data,
				dynamic: true,
				favorite: true,
			};
			dispatch(updateCurrentLocation(location));
			dispatch(addFavoriteLocation(location));
		}
	}, [data, status, dispatch]);

	useEffect(() => {
		if (positionConsent === null) {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [positionConsent]);

	/* Triggers when a user gives consent to let us use the browser location API */
	useEffect(() => {
		if (positionConsent?.consent && !hasDynamicLocation) {
			const { geolocation } = window.navigator;
			if (geolocation && geolocation.getCurrentPosition) {
				geolocation.getCurrentPosition(
					setUserPosition,
					reportRejection
				);
			}
		}
	}, [positionConsent, hasDynamicLocation]);

	return (
		<>
			{isModalOpen && (
				<LocationPermissionsPopup
					onToggleOpen={setIsModalOpen}
					onConsent={setPositionConsent}
				/>
			)}
		</>
	);
}
